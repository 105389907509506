import React from 'react';
import Position from "./Position";

class Release extends React.Component {
    render() {
        const release = this.props.release;
        const positions = release.positions;

        return <div className="product panel">
            <div className="productPosition">
                <div className="productName">
                    <div className="release">ID Pracownika:<br/> {release.workerId}</div>
                    <div className="release">{release.releaseDate}</div>
                </div>

                {positions.map(function (position, key) {
                    return <Position key={key} position={position} count={key + 1}/>;
                })}
            </div>
        </div>;
    }
}

export default Release;