import React from 'react';
import MessageBox from "./MessageBox";

class MessagesBox extends React.Component {

    render() {
        const messages = this.props.messages;
        if (messages.length > 0) {
            return messages.map((message, key) => {
                return <MessageBox key={key} kind={message.kind} text={message.text} beep={message.beep}/>
            })
        } else {
            return null;
        }

    }
}

export default MessagesBox;
