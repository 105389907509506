import React from 'react';
import barcode from 'Static/img/nfclogin2.png';
import OnScan from "../Utility/OnScan";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {login} from "../Modules/LayoutReducer";
import {setDevice} from "../Modules/VehicleReducer";

class LoginPage extends React.Component {
    componentDidMount() {
        window.imei = (imei) => this.props.setDevice(imei);
        OnScan.getInstance().addListener('login', /^[a-zA-Z0-9]+$/, (code) => this.props.login(code));
    }

    componentWillUnmount() {
        OnScan.getInstance().removeListener('login');
    }

    render() {
        return <div className="text-center">
                <h2>Zaloguj się identyfikatorem</h2><br/>
                <img src={barcode} alt={""}/>
            </div>
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({login, setDevice}, dispatch);

export default connect(null, mapDispatchToProps)(LoginPage);
