import React from 'react';
import ProductOrders from "../Components/Orders/ProductOrders";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {backToOrders, finish, getOrders, postOrders, updateOrders} from "../Modules/OrdersReducer";
import OnScan from "../Utility/OnScan";

class OrdersPage extends React.Component {
    componentDidMount() {
        this.props.getOrders(this.props.uuid, this.props.user.card);

        OnScan.getInstance().addListener('worker', /^[a-zA-Z0-9]+$/,
            (confirmCard) => this.props.postOrders(this.props.uuid, confirmCard, this.props.orders, this.props.workerId, this.props.user, this.props.step));
    }

    componentWillUnmount() {
        OnScan.getInstance().removeListener('worker');
    }

    render() {
        return <ProductOrders orders={this.props.orders} updateOrders={this.props.updateOrders} step={this.props.step} finish={this.props.finish} backToOrders={this.props.backToOrders}/>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({getOrders, updateOrders, postOrders, finish, backToOrders}, dispatch);

const mapStateToProps = state => ({
    orders: state.OrdersReducer.orders,
    step: state.OrdersReducer.step,
    uuid: state.LayoutReducer.uuid,
    workerId: state.LayoutReducer.workerId,
    user: state.LayoutReducer.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersPage));

