import _ from 'lodash';

class OnBack {
  static instance = null;

  listeners = [];

  /**
   * @returns {OnBack}
   */
  static getInstance() {
    if (OnBack.instance == null) {
      OnBack.instance = new OnBack();
    }

    return this.instance;
  }

  constructor() {
    window.back = () => this.back();
  }

  addListener(id, callback) {
    this.listeners.push({
      id,
      callback,
    });
  }

  removeListener(id) {
    this.listeners = _.filter(this.listeners, (listener) => listener.id !== id);
  }

  back() {
    let handled = false;
    let idx = this.listeners.length - 1;
    while (idx >= 0) {
      const listener = this.listeners[idx];

      if (listener.callback && listener.callback()) {
        handled = true;
        break;
      }
      idx--;
    }

    return handled ? 'handled' : 'unhandled';
  }

  destroy() {
    this.listeners = [];
    window.back = null;
  }
}

export default OnBack;
