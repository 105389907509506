import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import createBrowserHistory from 'history/createBrowserHistory';
import createMemoryHistory from 'history/createMemoryHistory';
import createRootReducer from './Modules/index';

let createHistory = typeof window === "undefined" ? createMemoryHistory : createBrowserHistory;

export const history = createHistory({
    forceRefresh: false
});

const initialState = {};
const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    createRootReducer(history),
    Object.assign({}, initialState),
    composedEnhancers
);

if (module.hot) {
    module.hot.accept('./Modules/index', () => {
        const nextRootReducer = require('./Modules/index').default;
        store.replaceReducer(nextRootReducer);
    });
}

export default store;
