import React from 'react';
import Release from "./Release";
import {Button} from "reactstrap";

class LastReleases extends React.Component {

    render() {
        const releases = this.props.releases;

        if (!releases) {
            return null;
        } else {
            return <div>
                <div className="text-center">
                    <Button className="btn btn-secondary btn-xl btn-space" onClick={this.props.finish}>Powrót do skanowania</Button>
                </div>
                {releases.map(function (release, key) {
                    return <Release key={key} release={release} count={key + 1}/>;
                })}
                <div className="text-center">
                    <Button className="btn btn-secondary btn-xl btn-space" onClick={this.props.finish}>Powrót do skanowania</Button>
                </div>
            </div>
        }
    }
}

export default LastReleases;
