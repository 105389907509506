import API from "../Utility/API";

class OrdersSource {
    getOrders(uuid, card) {
        return API.get('get/' + uuid + '/' + card);
    }

    getReleases() {
        return API.get('last/');
    }

    postOrders(uuid, postData, token) {
        return API.post('put/' + uuid, postData, token);
    }

}

export default new OrdersSource();