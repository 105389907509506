import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import store, {history} from 'store';
import App from 'Containers/App';

document.getElementsByTagName('html')[0].className = 'appWorking';

render(<Provider store={store}>
    <ConnectedRouter history={history}>
        <App/>
    </ConnectedRouter>
</Provider>, document.getElementById('root'));

if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept();
}
