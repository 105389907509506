import React from 'react';
import beepSrc from 'Static/audio/beep.mp3';

class Audio extends React.Component {

    render() {
        return <audio src={beepSrc} autoPlay/>;
    }
}

export default Audio;