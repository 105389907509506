import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Layout from '../Components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'Static/css/App.css';
import OnScan from "../Utility/OnScan";
import {bindActionCreators} from "redux";
import {logout, setLogoutTimer, setUuid} from "../Modules/LayoutReducer";
import { goBack } from '../Modules/Routing';
import OnBack from '../Utility/OnBack'

class App extends React.Component {

    componentDidMount() {
        OnScan.getInstance().addListener('setUuid', /^{"id":"(.*),"w":([0-9]+)}$/, (qrCode) => this.props.setUuid(qrCode));

        OnBack.getInstance().addListener('back', () => {
            this.props.goBack();

            return true;
        });

        window.macAddress = () => null;
        window.notification = () => null;
        window.setAndroidDevice = () => null;
        window.unsetFocus = () => null;
    }

    componentWillUnmount() {
        OnScan.getInstance().removeListener('setUuid');
        OnBack.getInstance().removeListener('back');
    }

    render() {
        return <Layout {...this.props}/>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({logout, setUuid, setLogoutTimer, goBack}, dispatch);

const mapStateToProps = state => ({
    isLoading: state.LayoutReducer.isLoading,
    user: state.LayoutReducer.user,
    messages: state.LayoutReducer.messages,
    logoutTimer: state.LayoutReducer.logoutTimer,
    expire: state.LayoutReducer.expire,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
