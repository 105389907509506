import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Navbar, NavbarBrand, Row} from 'reactstrap';

class Navigation extends React.Component {

    render() {
        return (
            <Navbar color="capi" dark expand={false}>
                <Row>
                    <Col xs="1" style={{padding: '0'}}>
                        <NavbarBrand tag={Link} to={!this.props.user ? "/" : this.props.user.route}>Imir</NavbarBrand>
                    </Col>
                    <Col xs="8">
                        {this.renderUser()}
                    </Col>
                    <Col xs="3">
                        {this.renderLogout()}
                    </Col>
                </Row>
            </Navbar>
        );
    }

    renderUser() {
        if (!this.props.user) return null;

        return <div className="userInfo">
            <span className="userName">{this.props.user.name}</span>
        </div>;
    }
    renderLogout() {
        if (!this.props.user) return null;

        return <Button size="sm" className="navButton" onClick={this.props.logout}>
                <span>Wyloguj</span>
            </Button>;

    }
}

export default Navigation;
