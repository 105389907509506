import React from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {finish} from "../Modules/OrdersReducer";
import LastReleases from "../Components/Releases/LastReleases";

class ReleasesPage extends React.Component {

    render() {
        return <LastReleases releases={this.props.releases} finish={this.props.finish}/>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({finish}, dispatch);

const mapStateToProps = state => ({
    releases: state.OrdersReducer.releases,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleasesPage));

