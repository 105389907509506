import React from 'react';
import Product from "./Product";
import ProductConfirm from "./ProductConfirm";
import {Button} from "reactstrap";

class ProductOrders extends React.Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.updateOrders(event.target, this.props.orders);

    }

    render() {
        if (!this.props.orders) {
            return null;
        } else {
            switch (this.props.step) {
                case 1:
                    return <form onSubmit={this.handleSubmit}>
                        {this.props.orders.map(function (order, key) {
                            return <Product key={key} product={order} count={key + 1}/>
                        })}
                        <br/>
                        <div className="text-center">
                            <input type="submit" className="btn btn-success btn-xl" value="Zatwierdź"/>
                        </div>
                    </form>;

                case 2:
                    return <div>
                        {this.props.orders.map(function (order, key) {
                            return <ProductConfirm key={key} product={order} count={key + 1}/>
                        })}
                        <div className="text-center">
                            <Button className="btn btn-secondary btn-xl btn-space" onClick={this.props.backToOrders}>
                                <span>Wstecz</span>
                            </Button>
                            <Button className="btn btn-danger btn-xl btn-space" onClick={this.props.finish}>
                                <span>Anuluj</span>
                            </Button>
                        </div>
                    </div>;

                case 3:
                case 4:
                    return <div>
                        {this.props.orders.map(function (order, key) {
                            return <ProductConfirm key={key} product={order} count={key + 1}/>
                        })}
                        <br/>
                        <div className="text-center">
                        <Button className="btn btn-secondary btn-xl" onClick={this.props.finish}>
                            <span>Zakończ</span>
                        </Button>
                        </div>
                    </div>;

                default:
                    return null;
            }
        }
    }
}

export default ProductOrders;
