import CryptoJS from "crypto-js";

class API {
    constructor() {
        this.apiUrl = process.env.REACT_APP_API_URL;
        this.client = process.env.REACT_APP_API_CLIENT;
    }

    get(url) {
        let request = new Request(this.apiUrl + url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Client': this.client,
            })
        });

        return fetch(request).then(response => {
            return response.json().then(data => ({
                    data: data,
                    status: response.status,
                    text: data.message
                })
            ).then(res => {
                return res;
            })
        }).catch(error => {
            return {
                data: null,
                status: -1,
                text: error.message
            };
        });
    }

    post(url, postData, token) {
        let jsonBody = JSON.stringify(postData);
        let apiKey = CryptoJS.HmacSHA256(jsonBody.replace(/"/g, ''), token);

        let request = new Request(this.apiUrl + url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Api-Key': apiKey,
                'Cookies': [{'XDEBUG_SESSION_START': 'PHPSTORM'}],
            }),
            body: jsonBody
        });

        return fetch(request).then(response => {
            return response.json().then(data => ({
                    data: data,
                    status: response.status,
                    text: data.message
                })
            ).then(res => {
                return res;
            })
        }).catch(error => {
            return {
                data: null,
                status: -1,
                text: error.message
            };
        });
    }
}

export default new API();