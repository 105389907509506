import React from 'react';
import Loader from "./Loader";

class LayoutLoader extends React.Component {
    render() {
        return <div className="loadingBox">
            <Loader/>
        </div>;
    }
}

export default LayoutLoader;