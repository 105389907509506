import {SET_MESSAGE} from "./LayoutReducer";
import {push} from "./Routing";
import OrdersSource from "./OrdersSource";

export const GET_ORDERS = 'orders/GET_ORDERS';
export const UPDATE_ORDERS = 'orders/UPDATE_ORDERS';
export const POST_ORDERS = 'orders/POST_ORDERS';
export const FINISH = 'orders/FINISH';
export const SET_STEP = 'orders/SET_STEP';
export const GET_RELEASES = 'orders/GET_RELEASES';

const initialState = {
    orders: null,
    step: 0,
    releases: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.orders,
                step: 1
            };

        case UPDATE_ORDERS:
            return {
                ...state,
                orders: action.orders,
                step: 2
            };

        case POST_ORDERS:
            return {
                ...state,
                step: 4
            };

        case FINISH:
            return {
                ...state,
                orders: null,
                step: 0
            };

        case SET_STEP:
            return {
                ...state,
                step: action.step
            };

        case GET_RELEASES:
            return {
                ...state,
                step: 10,
                releases: action.releases
            };

        default:
            return state;
    }
};

export const getOrders = (orderId, card) => dispatch => {
    dispatch({type: SET_MESSAGE, kind: 'load', text: 'Zlecenie w trakcie odczytu...', beep: false});
    OrdersSource.getOrders(orderId, card).then((result) => {
        if (result.status === 200) {
            dispatch({type: GET_ORDERS, orders: result.data});
            dispatch({type: SET_MESSAGE, kind: 'success', text: 'Odczytano zlecenie.', beep: false});
        } else {
            let errorMsg = 'Odczyt zlecenia niemożliwy! ' + result.text;
            dispatch({type: SET_MESSAGE, kind: 'warning', text: errorMsg, beep: false});
            push('/app/qr')(dispatch);
        }
    }).catch(error => {
        dispatch({type: SET_MESSAGE, kind: 'error', text: error.message, beep: true});
        push('/app/qr')(dispatch);
    });
};

export const updateOrders = (formData, orders) => dispatch => {
    let i;
    let releases = [];
    for (i = 0; i < formData.length; i++) {

        let releaseId = parseInt(formData[i].name);
        let quantity = formData[i].value;

        let newOrder = findOrder(releaseId, orders);
        if (newOrder) {
            newOrder.quantity = quantity;
            releases.push(newOrder);
        }
    }

    dispatch({type: UPDATE_ORDERS, orders: releases});
    dispatch({type: SET_MESSAGE, kind: 'info', text: 'Potwierdź wydanie skanując kartę pracownika.', beep: false});
};

export const postOrders = (uuid, confirmCard, orders, workerId, user, step) => dispatch => {
    if (step === 4) {
        dispatch({type: SET_MESSAGE, kind: 'warning', text: 'Zlecenie zostało już wysłane!', beep: true});
        return;
    }

    if (step !== 2) {
        return;
    }

    dispatch({type: SET_MESSAGE, kind: 'load', text: 'Zlecenie w trakcie weryfikacji...', beep: false});
    dispatch({type: SET_STEP, step: 3});
    let postData = {
        orders: orders,
        workerId: workerId,
        card: confirmCard.toUpperCase(),
        user: user.card,
        expire: Math.round(Date.now() / 1000) + 15
    };
    OrdersSource.postOrders(uuid, postData, user.token).then((result) => {
        if (result.status === 201) {
            dispatch({type: POST_ORDERS});
            dispatch({type: SET_MESSAGE, kind: 'success', text: 'Zlecenie zostało zaakceptowane.', beep: false});
        } else {
            let errorMsg = 'Zlecenie odrzucone! ' + result.text;
            dispatch({type: SET_MESSAGE, kind: 'error', text: errorMsg, beep: true});
            dispatch({type: SET_STEP, step: 2});
        }
    }).catch(error => {
        dispatch({type: SET_MESSAGE, kind: 'error', text: error.message, beep: true});
        dispatch({type: SET_STEP, step: 2});
    });
};

export const getReleases = () => dispatch => {
    dispatch({type: SET_MESSAGE, kind: 'load', text: 'Odczyt ostatnich wydań...', beep: false});
    OrdersSource.getReleases().then((result) => {
        if (result.status === 200) {
            dispatch({type: GET_RELEASES, releases: result.data});
            dispatch({type: SET_MESSAGE, kind: 'info', text: 'Lista ostatnich wydań.', beep: false});
            push('/app/releases')(dispatch);
        } else {
            let errorMsg = 'Odczyt niemożliwy! ' + result.text;
            dispatch({type: SET_MESSAGE, kind: 'warning', text: errorMsg, beep: false});
            push('/app/qr')(dispatch);
        }
    }).catch(error => {
        dispatch({type: SET_MESSAGE, kind: 'error', text: error.message, beep: true});
        push('/app/qr')(dispatch);
    });
};

export const finish = () => dispatch => {
    dispatch({type: FINISH});
    dispatch({type: SET_MESSAGE, kind: 'info', text: 'Zeskanuj kod QR ze zlecenia', beep: false});
    push('/app/qr')(dispatch);
};

export const backToOrders = () => dispatch => {
    dispatch({type: SET_STEP, step: 1});
    dispatch({type: SET_MESSAGE, kind: '', text: '', beep: false});
};

function findOrder(id, orders) {
    let i;
    for (i = 0; i < orders.length; i++) {
        if (orders[i].releaseId === id) {
            return orders[i];
        }
    }
}

