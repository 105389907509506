import React from 'react';
import qrcode from 'Static/img/qrcode.png';
import {finish, getReleases} from "../Modules/OrdersReducer";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "reactstrap";

class QrCodePage extends React.Component {
    componentDidMount() {
        if (this.props.step > 0) {
            this.props.finish();
        }
    }

    render() {
        return <div>
            <div className="text-center">
                <br/>
                <img src={qrcode} alt={""}/>
                <br/><br/>
                <Button className="btn btn-secondary btn-xl" onClick={this.props.getReleases}>
                    <span>Ostatnie wydania</span>
                </Button>
            </div>
        </div>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({finish, getReleases}, dispatch);

const mapStateToProps = state => ({
    step: state.OrdersReducer.step,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QrCodePage));
