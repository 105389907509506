import React from 'react';
import {getStatuses, setDevice} from "../Modules/VehicleReducer";
import {clearMessages} from "../Modules/LayoutReducer";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import StatusButton from "../Components/Vehicle/StatusButton";
import {Alert} from "reactstrap";

class VehiclePage extends React.Component {
    componentDidMount() {
        this.props.clearMessages();
        this.props.getStatuses(this.props.device);
    }

    render() {
        if (this.props.statuses == null) {
            return null;
        }

        return <div>
            {this.renderActualStatus()}
            {this.props.statuses.map(function (status) {
                return <StatusButton key={status.id} status={status}/>
            })}
        </div>;
    }

    renderActualStatus() {
        let actual = this.props.actual;

        return <Alert color={actual.class} fade={false}>Aktualny status: <b>{actual.name}</b><br/>Od: <b>{actual.start}</b></Alert>
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({getStatuses, setDevice, clearMessages}, dispatch);

const mapStateToProps = state => ({
    statuses: state.VehicleReducer.statuses,
    actual: state.VehicleReducer.actual,
    device: state.VehicleReducer.device,
    user: state.LayoutReducer.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehiclePage));
