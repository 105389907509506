import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Navigation from "./Layout/Navigation";
import LayoutLoader from "./Utility/LayoutLoader";
import LoginPage from "../Containers/LoginPage";
import AppRouter from "../Containers/AppRouter";
import MessagesBox from "./Layout/MessagesBox";

class Layout extends React.Component {

    render() {
        return <div className="App">
            <Navigation user={this.props.user} logout={this.props.logout} getOrders={this.props.getOrders}/>
            <main className="main">
                <MessagesBox messages={this.props.messages}/>
                {this.renderBody()}
            </main>
        </div>;
    }

    renderBody() {

        if (this.props.isLoading) {
            return <LayoutLoader/>;
        }

        return (
            <Switch>
                <Route path="/app" component={AppRouter}/>
                <Route exact path="/" component={LoginPage}/>
            </Switch>
        );
    }
}

export default Layout;
