import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import LayoutReducer from "./LayoutReducer";
import OrdersReducer from "./OrdersReducer";
import VehicleReducer from "./VehicleReducer";

export default (history) => combineReducers({
    router: connectRouter(history),
    LayoutReducer,
    OrdersReducer,
    VehicleReducer,
});
