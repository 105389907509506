import React from 'react';
import Audio from "./Audio";

class MessageBox extends React.Component {
    render() {
        const kind = this.props.kind;
        const text = this.props.text;

        return <div>
            <div className={kind}>{text}</div>
            {this.renderAudio()}
        </div>;
    }

    renderAudio() {
        const beep = this.props.beep;
        return beep ? <Audio/> : null;
    }
}

export default MessageBox;