import React from 'react';
import {Button} from "reactstrap";
import {changeStatus} from "../../Modules/VehicleReducer";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

class StatusButton extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(status) {
        this.props.changeStatus(this.props.device, status, this.props.user);
    }

    render() {
        const status = this.props.status;
        const actual = this.props.actual;

        return <Button
            onClick={() => this.onClick(status.symbol)}
            color={status.class}
            key={status.id}
            size="lg"
            disabled={actual.status === status.symbol}
            block={true}
        >
            {status.name}
        </Button>
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({changeStatus}, dispatch);

const mapStateToProps = state => ({
    user: state.LayoutReducer.user,
    actual: state.VehicleReducer.actual,
    device: state.VehicleReducer.device,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusButton));