import React from 'react';

class ProductConfirm extends React.Component {
    render() {
        const product = this.props.product;
        const count = this.props.count;
        let productName = product.productName;

        if (parseInt(product.quantity) === 0) {
            productName = <span className="line-through">{product.productName}</span>;
        }

        return <div className="productConfirm">
            <div className="quantity">
                ilość: <span className="quantityTxt">{product.quantity}</span>
            </div>
            <div className="productName">
                <span className="font-weight-bold">{count}.</span> {productName}
            </div>
        </div>;
    }
}

export default ProductConfirm;