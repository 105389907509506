import {SET_MESSAGE} from "./LayoutReducer";
import VehicleSource from "./VehicleSource";

export const SET_STATUSES = 'vehicles/SET_STATUSES';
export const SET_DEVICE = 'vehicles/SET_DEVICE';

const initialState = {
    statuses: null,
    actual: null,
    device: "unknown",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STATUSES:
            return {
                ...state,
                statuses: action.data.statuses,
                actual: action.data.actual,
            };

        case SET_DEVICE:
            return {
                ...state,
                device: action.device
            };

        default:
            return state;
    }
};

export const setDevice = (device) => dispatch => {
    dispatch({type: SET_DEVICE, device: device});
};

export const getStatuses = (device) => dispatch => {
    dispatch({type: SET_MESSAGE, kind: 'load', text: 'Odczyt danych...', beep: false});
    VehicleSource.getStatuses(device).then((result) => {
        if (result.status === 200) {
            dispatch({type: SET_STATUSES, data: result.data});
            dispatch({type: SET_MESSAGE, kind: null, text: null, beep: false});
        } else {
            dispatch({type: SET_MESSAGE, kind: 'warning', text: result.text, beep: false});
        }
    }).catch(error => {
        dispatch({type: SET_MESSAGE, kind: 'error', text: error.message, beep: true});
    });
};

export const changeStatus = (device, status, user) => dispatch => {
    dispatch({type: SET_MESSAGE, kind: 'load', text: 'Zapis danych...', beep: false});
    let postData = {
        device: device,
        workerId: user.id,
        status: status,
        user: user.card,
    };

    VehicleSource.postStatus(postData, user.token).then((result) => {
        if (result.status === 201) {
            dispatch({type: SET_MESSAGE, kind: null, text: null, beep: false});
        } else {
            dispatch({type: SET_MESSAGE, kind: 'error', text: result.text, beep: false});
        }
    }).catch(error => {
        dispatch({type: SET_MESSAGE, kind: 'error', text: error.message, beep: true});
    }).finally(() => {
        getStatuses(device)(dispatch);
    });
};
