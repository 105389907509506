import React from 'react';

class Position extends React.Component {
    render() {
        const position = this.props.position;
        const count = this.props.count;

        return <div className="productConfirm releasePosition">
            <div className="quantity releaseQuantity">
                ilość: <span className="quantityTxt">{position.quantity}</span>
            </div>
            <div className="productName">
                <span className="font-weight-bold">{count}.</span> {position.productName}
            </div>
        </div>;
    }
}

export default Position;