import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import LayoutLoader from "../Components/Utility/LayoutLoader";
import QrCodePage from "./QrCodePage";
import OrdersPage from "./OrdersPage";
import {push} from "../Modules/Routing";
import {postOrders} from "../Modules/OrdersReducer";
import ReleasesPage from "./ReleasesPage";
import VehiclePage from "./VehiclePage";

class AppRouter extends React.Component {
    componentDidMount() {
        if (!this.props.user) {
            this.props.push('/');
        }

    }

    render() {
        if (!this.props.user) {
            return <LayoutLoader/>;
        }

        const parent = this.props.match.url;

        return <Switch>
            <Route path={parent + '/qr'} component={QrCodePage}/>
            <Route path={parent + '/orders'} component={OrdersPage}/>
            <Route path={parent + '/releases'} component={ReleasesPage}/>
            <Route path={parent + '/vehicle'} component={VehiclePage}/>
        </Switch>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({push, postOrders}, dispatch);

const mapStateToProps = state => ({
    user: state.LayoutReducer.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
