import API from "../Utility/API";

class VehicleSource {
    getStatuses(device) {
        return API.get('gps/status/' + device);
    }

    postStatus(postData, token) {
        return API.post('gps/change', postData, token);
    }

}

export default new VehicleSource();