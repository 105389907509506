import {goBack as reduxGoBack, push as reduxPush, replace as reduxReplace} from "connected-react-router";

export const push = (to) => {
    return dispatch => {
        dispatch(reduxPush(to));
    };
};

export const replace = (to) => {
    return dispatch => {
        dispatch(reduxReplace(to));
    };
};

export const goBack = () => {
    return dispatch => {
        dispatch(reduxGoBack());
    };
};
