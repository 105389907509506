import React from 'react';

class Product extends React.Component {
    render() {
        const product = this.props.product;
        const count = this.props.count;

        return <div className="product">
            <label className="productPosition" id={product.releaseId}>
                {count}. {product.productName} <input type="number" name={product.releaseId} className="form-control quantityEdit" min="0" max={product.quantityMax} defaultValue={product.quantity}/>
            </label>
        </div>;
    }
}

export default Product;